import React from "react"
import Fade from "react-reveal/Fade"
import { Trans } from "gatsby-plugin-react-i18next"
import Accordion from "../Accordion/Accordion"

const faqs = [
  { question: "faq.question.one", answer: "faq.answer.one" },
  { question: "faq.question.two", answer: "faq.answer.two" },
  { question: "faq.question.three", answer: "faq.answer.three" },
]

export default function Faq() {
  return (
    <div className="faq">
      <Fade bottom duration={1000} delay={200} distance="0px">
        <h1 className="text-center faq__title">
          <Trans>faq.title</Trans>
        </h1>
      </Fade>
      {faqs.map(({ question, answer }, i) => (
        <Accordion title={question} content={answer} key={i} />
      ))}
    </div>
  )
}
