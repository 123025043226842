import React from "react"
import Fade from "react-reveal/Fade"
import { Trans } from "gatsby-plugin-react-i18next"
import ServicesItem from "./ServicesItem"
import Nannies from "../../assets/images/services/nannie.jpg"
import Housekeepers from "../../assets/images/services/housekeeper.jpg"
import CareCompanion from "../../assets/images/services/carecompanion.jpg"
import DomesticCouple from "../../assets/images/services/domesticcouple.jpg"
import Chefs from "../../assets/images/services/chefs.jpg"
import Drivers from "../../assets/images/services/drivers.jpg"
import Managers from "../../assets/images/services/manager.jpg"
import Assistant from "../../assets/images/services/assistant.jpg"

const dataFirst = [
  { name: "services.nannies", image: Nannies },
  { name: "services.housekeepers", image: Housekeepers },
  { name: "services.careCompanion", image: CareCompanion },
  { name: "services.domesticCouple", image: DomesticCouple },
]

const dataSecond = [
  { name: "services.chefs", image: Chefs },
  { name: "services.drivers", image: Drivers },
  { name: "services.managers", image: Managers },
  { name: "services.assistant", image: Assistant },
]

export default function Services() {
  return (
    <div className="services" id="2">
      <Fade bottom duration={1000} delay={200} distance="0px">
        <div className="title">
          <h1 className="text-center">
            <Trans>services.title</Trans>
          </h1>
        </div>
      </Fade>
      <div className="row m-0">
        <ServicesItem services={dataFirst} />
      </div>
      <div className="row m-0">
        <ServicesItem services={dataSecond} />
      </div>
    </div>
  )
}
