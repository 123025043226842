import React, { Component } from "react"
import Fade from "react-reveal/Fade"
import { Trans } from "gatsby-plugin-react-i18next"
import { Link } from "react-scroll"

export default class ServicesItem extends Component {
  render() {
    const { services } = this.props
    return (
      <>
        {!!services &&
          services.map((service, i) => {
            return (
              <Fade
                key={i + "_service"}
                top
                duration={1000}
                delay={i * 200}
                distance="50px"
              >
                <div className="col-lg-3 col-md-6 col-sm-6 col-6 service">
                  <Link to="5" smooth duration={1000}>
                    <div className="service__block shadow-lg">
                      <div className="service__img">
                        <img
                          src={service.image}
                          alt={<Trans>{service.name}</Trans>}
                        />
                      </div>
                      <div className="title__block">
                        <p className="title">
                          <Trans>{service.name}</Trans>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </Fade>
            )
          })}
      </>
    )
  }
}
