import React from "react"
import Fade from "react-reveal/Fade"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Clients({ posts }) {
  const post = posts.nodes
    .filter(post => post.tags.nodes[0].name === "clients")
    .shift()
  const featuredImage = {
    image: post.featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <div className="clients-wrapper" id="4">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            {featuredImage?.image && (
              <Fade bottom duration={1000} delay={400} distance="20px">
                <div className="about-wrapper__image">
                  <GatsbyImage
                    className={"rounded shadow-lg"}
                    image={featuredImage.image}
                    alt={featuredImage.alt}
                  />
                </div>
              </Fade>
            )}
          </div>
          <div className="col-md-6 col-sm-12 center-v">
            <Fade bottom duration={1000} delay={300} distance="0px">
              <div className="title">
                <h1>{post.title}</h1>
              </div>
            </Fade>
            <Fade bottom duration={1000} delay={500} distance="30px">
              <div
                className="contact-wrapper__text"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}
