import React from "react"
import Fade from "react-reveal/Fade"
import { Link } from "react-scroll"
import { Trans } from "gatsby-plugin-react-i18next"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Hero({ posts }) {
  const post = posts.nodes.filter(post => post.title === "Hero").shift()
  const featuredImage = {
    image: post.featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <div className="hero">
      <GatsbyImage image={featuredImage.image} alt={featuredImage.alt} />
      <div className="hero__container">
        <Fade duration={1000} delay={500} distance="10px">
          <div
            className="hero__title"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </Fade>
        <Fade duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="1" smooth duration={1000}>
                <Trans>knowMore</Trans>
              </Link>
            </span>
          </p>
        </Fade>
      </div>
    </div>
  )
}
