import React from "react"
import Fade from "react-reveal/Fade"
import { GatsbyImage } from "gatsby-plugin-image"

export default function PageContent({ post }) {
  const featuredImage = {
    image: post.featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <div className="page-content container">
      <div className="row about-wrapper" id="1">
        <div className="col-lg-12">
          <Fade bottom duration={1000} delay={200} distance="0px">
            <div className="title">
              <h1
                className="text-center"
                dangerouslySetInnerHTML={{ __html: post.title }}
              />
            </div>
          </Fade>
        </div>
        {featuredImage?.image && (
          <div className="col-md-5 col-sm-12">
            <Fade bottom duration={1000} delay={400} distance="20px">
              <div className="about-wrapper__image">
                <GatsbyImage
                  className={"rounded shadow-lg"}
                  image={featuredImage.image}
                  alt={featuredImage.alt}
                />
              </div>
            </Fade>
          </div>
        )}
        <div className="col-md-7 col-sm-12">
          <Fade left={true} duration={1000} delay={600} distance="30px">
            <div className="about-wrapper__info">
              {!!post.content && (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: post.content }}
                />
              )}
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}
