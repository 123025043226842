import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageContent from "../components/PageContent/PageContent"
import Services from "../components/Services/Services"
import Faq from "../components/Faq/Faq"
import Clients from "../components/Clients/Clients"
import Candidates from "../components/Candidates/Candidates"
import Hero from "../components/Hero/Hero"
import ScrollUpButton from "react-scroll-up-button"

function getHomepageContent(data, languageSlug) {
  const { language, translations } = data
  const translation = translations
    .filter(translation => translation.language.slug === languageSlug)
    .shift()
  return language.slug === languageSlug ? data : translation
}

const PageTemplate = ({ data: { allWpPost, allWpPage } }) => {
  const wpPost = allWpPage.nodes[0]
  const content = getHomepageContent(wpPost, wpPost.language.slug)
  return (
    <Layout language={wpPost.language.slug}>
      <Seo title={wpPost.title} description={wpPost.excerpt} />
      <Hero posts={allWpPost} />
      <PageContent post={content} />
      <Services />
      <Candidates posts={allWpPost} />
      <Clients posts={allWpPost} />
      <Faq />
      <ScrollUpButton ContainerClassName="scroll-up" />
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById($id: String!, $language: String!) {
    allWpPage(filter: { language: { slug: { eq: $language } } }) {
      nodes {
        uri
        id
        content
        title
        language {
          code
          slug
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1500, quality: 100)
              }
            }
          }
        }
        translations {
          content
          language {
            slug
          }
          seo {
            metaDesc
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            title
            opengraphSiteName
            opengraphDescription
            opengraphImage {
              sourceUrl
            }
          }
        }
      }
    }
    allWpPost(filter: { language: { slug: { eq: $language } } }) {
      nodes {
        content
        title
        slug
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1500, quality: 100)
              }
            }
          }
        }
      }
    }
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      language {
        code
        slug
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1500, quality: 100)
            }
          }
        }
      }
      translations {
        content
        language {
          slug
        }
        seo {
          metaDesc
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          title
          opengraphSiteName
          opengraphDescription
          opengraphImage {
            sourceUrl
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
