import React from "react"
import Fade from "react-reveal/Fade"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Candidates({ posts }) {
  const post = posts.nodes
    .filter(post => post.tags.nodes[0].name === "candidates")
    .shift()
  const featuredImage = {
    image: post.featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  if (typeof document !== "undefined") {
    document.addEventListener("click", event => {
      if (!event.target.matches(".candidates .text-color-main")) return

      document.querySelector(".contacts").scrollIntoView({
        behavior: "smooth",
      })
    })
  }

  return (
    <div className="candidates container" id="3">
      <div className="row">
        <div className="col-md-6 candidates__content">
          <Fade bottom duration={1000} delay={300} distance="0px">
            <div className="title">
              <h1>{post.title}</h1>
            </div>
          </Fade>
          <Fade bottom duration={1000} delay={500} distance="20px">
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </Fade>
        </div>
        <div className="col-md-6">
          <Fade bottom duration={1000} delay={700} distance="0px">
            {featuredImage?.image && (
              <Fade bottom duration={1000} delay={400} distance="20px">
                <div className="about-wrapper__image">
                  <GatsbyImage
                    className={"rounded shadow-lg"}
                    image={featuredImage.image}
                    alt={featuredImage.alt}
                  />
                </div>
              </Fade>
            )}
          </Fade>
        </div>
      </div>
    </div>
  )
}
