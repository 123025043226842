import React, { useRef, useState } from "react"
import { Trans } from "gatsby-plugin-react-i18next"
import Chevron from "../../assets/images/chevron.svg"

export default function Accordion(props) {
  const [active, setActive] = useState(false)
  const content = useRef(null)
  const [height, setHeight] = useState("0px")

  function toggleAccordion() {
    setActive(!active)
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`)
  }

  return (
    <div className="accordion__section">
      <div
        className={`accordion ${active ? "active" : ""}`}
        onClick={toggleAccordion}
      >
        <h3 className="accordion__title">
          <Trans>{props.title}</Trans>
        </h3>
        <img
          className={active ? "chevron rotate" : "chevron"}
          src={Chevron}
          alt="chevron"
        />
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="accordion__content"
      >
        <p className="accordion__text">
          <Trans>{props.content}</Trans>
        </p>
      </div>
    </div>
  )
}
